import { axiosClient, axiosApi } from "../api/axiosClient";
import {
  BlogType,
  ContactFormValues,
  HomeContactFormValues,
  JobType,
} from "../types/types";

export const handleGetJobs = async (): Promise<{
  data: JobType[] | null;
  status: number;
  meta: any;
}> => {
  try {
    const response = await axiosApi.get("/aes-jobs", {
      params: {
        populate: {
          companyImage: {
            populate: "*",
          },
        },
      },
    });

    if (response.status === 200) {
      return {
        data: response.data.data,
        meta: response.data.meta,
        status: response.status,
      };
    }
  } catch (error) {
    console.error("Error:", error);
  }
  return { data: null, meta: null, status: 500 };
};

export const handleGetJobBySlug = async (
  slug: string,
): Promise<{
  data: JobType | null;
  status: number;
}> => {
  try {
    const response = await axiosApi.get(`/aes-jobs`, {
      params: {
        populate: {
          companyImage: {
            populate: "*",
          },
        },
      },
    });

    if (response.status === 200) {
      return { data: response.data.data[0], status: response.status };
    }
  } catch (error) {
    console.error("Error:", error);
  }
  return { data: null, status: 500 };
};

export const handleGetBlogs = async (
  currentPage: number,
  limit: number,
): Promise<{
  data: BlogType[] | null;
  status: number;
  meta: any;
}> => {
  try {
    const response = await axiosApi.get("/aes-blogs", {
      params: {
        populate: {
          image: {
            populate: "*",
          },
          aes_author: {
            populate: "*",
          },
          aes_tags: {
            populate: "*",
          },
        },
        pagination: {
          page: currentPage,
          pageSize: limit,
        },
      },
    });

    if (response.status === 200) {
      return {
        data: response.data.data,
        meta: response.data.meta,
        status: response.status,
      };
    }
  } catch (error) {
    console.error("Error:", error);
  }
  return { data: null, meta: null, status: 500 };
};

export const handleGetBlogBySlug = async (
  slug: string,
): Promise<{
  data: BlogType | null;
  status: number;
}> => {
  try {
    const response = await axiosApi.get(`/aes-blogs`, {
      params: {
        "filters[slug][$eq]": slug,
        populate: "*",
        aes_author: {
          populate: "*",
        },
        aes_tags: {
          populate: "*",
        },
      },
    });

    if (response.status === 200) {
      return { data: response.data.data[0], status: response.status };
    }
  } catch (error) {
    console.error("Error:", error);
  }
  return { data: null, status: 500 };
};

export const sendHomeContactPageData = async (
  values: HomeContactFormValues & { from: string },
) => {
  try {
    const response = await axiosClient.post("/sendMail", values);
    return { status: true, response: response };
  } catch (error) {
    console.error("Error:", error);
    return { status: false, response: null };
  }
};

export const sendContactPageData = async (
  values: ContactFormValues & { from: string },
) => {
  try {
    const response = await axiosClient.post("/sendMail", values);
    return { status: true, response: response };
  } catch (error) {
    console.error("Error:", error);
    return { status: false, response: null };
  }
};

// export const fetchBlogs = async (page: number, limit: number) => {
//   try {
//     // Calculate the start and end index for pagination
//     const startIndex = (page - 1) * limit;
//     const endIndex = startIndex + limit;
//
//     // Simulate a paginated response from mock data
//     const paginatedBlogs = blogs.slice(startIndex, endIndex);
//
//     // Simulate a successful response
//     return {
//       status: true,
//       response: {
//         data: paginatedBlogs,
//         currentPage: page,
//         totalPages: Math.ceil(blogs.length / limit),
//       },
//     };
//   } catch (error) {
//     console.error("Error:", error);
//     return { status: false, response: null };
//   }
// };
//
// export const fetchBlogById = async (id: string) => {
//   try {
//     // Simulate finding the blog by ID
//     const blog = blogs.find((blog) => blog._id === id);
//
//     // Simulate a successful response
//     return {
//
//       status: true,
//       response: {
//         data: blog,
//       },
//     };
//   } catch (error) {
//     console.error("Error:", error);
//     return { status: false, response: null };
//   }
// };
