import React from "react";
import { Link } from "react-router-dom";
import { BlogType } from "../../types/types";
import { format } from "date-fns";

interface BlogProps {
  blog: BlogType;
}

const Blog = ({ blog }: BlogProps) => {
  return (
    <div className="relative">
      <Link
        to={`/blog/${blog.slug}`}
        state={{ blog }}
        onClick={() => window.scrollTo(0, 0)}
        className="flex px-3 py-3"
      >
        <div className="max-w-sm overflow-hidden">
          <img
            className="max-h-[200px] w-80 object-cover hover:scale-105 transition-all duration-300"
            src={`https://admin.aesrecruitment.com${blog.image.url}`}
            alt="Sunset in the mountains"
          />
          <div className="py-4">
            <div className="mb-2 text-sm font-semibold text-primary">
              {blog.aes_author.name} •{" "}
              {format(new Date(blog.publishedAt), "dd MMM yyyy")}
            </div>
            <div className="flex mb-2 justify-between cursor-pointer [&>svg]:hover:rotate-45 apply underline-offset-4 [&>div]:hover:text-black [&>svg]:hover:text-black transition-all duration-400">
              <p className="text-xl transition-all inter-bold duration-400">
                {blog.title}
              </p>
              <svg
                className="text-2xl transition-all duration-300"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M7.33337 17L17.3334 7M17.3334 7H7.33337M17.3334 7V17"
                  stroke="#1A1A1A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            {/* <p className="text-base text-gray-700">{blog.subHeading}</p> */}
          </div>
          <div className="mb-1">
            <div className="flex flex-wrap gap-2 mb-2">
              {blog.aes_tags.map((tag) => (
                <div className="py-1 px-2 bg-opacity-10 rounded-lg bg-[#267DFF] text-primary text-xs">
                  {tag.name}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Blog;
