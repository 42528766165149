import React from "react";
import { FaLocationDot, FaMoneyBillWave } from "react-icons/fa6";
import { GoArrowUpRight } from "react-icons/go";
import { JobType } from "../../types/types";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

type Props = {
  jobs: JobType[];
};

const Jobs = ({ jobs }: Props) => {
  const navigate = useNavigate();

  return (
    <>
      {jobs.map((job, index) => (
        <div
          key={index}
          className="border p-4 mb-4 inter-regular hover:bg-primary transition-all duration-400 [&>div>div>.date]:hover:text-white [&>p]:hover:text-white [&>div>div>h2]:hover:text-white rounded-md shadow-md bg-white [&>.location>p]:hover:text-white [&>.pay-wrapper>.pay>p]:hover:text-white [&>div>.apply>*]:hover:text-white"
        >
          <div className="flex justify-between">
            <div className="flex gap-2 items-center">
              <img
                src={`https://admin.aesrecruitment.com${job.companyImage.url}`}
                className="w-10 h-10 object-cover rounded-full"
                alt={job.companyName}
              />
              <h2 className="text-lg">{job.title}</h2>
            </div>
            <div className="flex gap-4 items-center">
              <p className="text-sm text-[#747474] date transition-all duration-400">
                Posted {format(new Date(job.publishedAt), "MMM dd, yyyy")}{" "}
              </p>
              <p className="text-sm text-[#747474] px-4 py-1 bg-[#f3f3f3] rounded">
                {job.jobType}
              </p>
            </div>
          </div>
          <p className="opacity-50">{job.companyName}</p>
          <div className="flex gap-2 items-center location transition-all duration-400">
            <FaLocationDot className="text-[#747474] opacity-50" />
            <p className="opacity-50">{job.location}</p>
          </div>
          <div className="flex justify-between pay-wrapper">
            <div className="flex gap-2 items-center pay transition-all duration-400">
              <FaMoneyBillWave className="text-[#747474] opacity-50" />
              <p className="opacity-50">
                {job.currency === "GBP" ? (
                  <>£</>
                ) : job.currency === "EUR" ? (
                  <>€</>
                ) : job.currency === "INR" ? (
                  <>₹</>
                ) : (
                  <>$</>
                )}
                {job.minSalaryRange} - {job.maxSalaryRange}
              </p>
            </div>

            <div
              onClick={() => navigate(`/jobs/${job.slug}`)}
              className="flex items-center cursor-pointer [&>svg]:hover:rotate-45 apply underline-offset-4 [&>div]:hover:text-black [&>svg]:hover:text-black transition-all duration-400"
            >
              <div className="text-primary inter-regular text-sm underline transition-all duration-400">
                Apply now
              </div>
              <GoArrowUpRight className="text-primary transition-all duration-300 p-1 text-2xl" />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Jobs;
