import React from "react";
import { BlogType } from "../../types/types";
import Blog from "./Blog";

interface BlogListProps {
  blogs: BlogType[];
}

const BlogList = ({ blogs }: BlogListProps) => {
  return (
    <div>
      <h2 className="mb-4 ml-2 text-3xl font-bold">All blog posts</h2>
      <div className="grid grid-cols-1 gap-4 mb-2 md:grid-cols-2 lg:grid-cols-3 sm:mb-4">
        {blogs.map((blog) => (
          <Blog key={blog.slug} blog={blog} />
        ))}
      </div>
    </div>
  );
};

export default BlogList;
