import React, { createContext, useState, useEffect, ReactNode } from "react";
import { toast } from "react-toastify";
import { handleGetBlogs, handleGetJobs } from "../service/service";
import { BlogType, JobType } from "../types/types";

export type UserOrNull = {
  id: string;
  email: string;
} | null;

export interface AuthContextType {
  isAuthenticated: boolean;
  blogs: BlogType[];
  setBlogs: React.Dispatch<React.SetStateAction<BlogType[]>>;
  currentPage: number;
  totalPages: number;
  handlePageChange: (page: number) => void;
  user: UserOrNull;
  setUser: React.Dispatch<React.SetStateAction<UserOrNull>>;
  jobs: JobType[];
  setJobs: React.Dispatch<React.SetStateAction<JobType[]>>;
  loading: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

export const fetchAllJobs = async (
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setJobs: React.Dispatch<React.SetStateAction<JobType[]>>,
) => {
  setLoading(true);
  try {
    const response = await handleGetJobs();
    if (response.status !== 200) {
      toast.error("Could not load the data");
    } else {
      setJobs(response.data as JobType[]);
    }
  } catch (error) {
    console.error("Error fetching jobs:", error);
  } finally {
    setLoading(false);
  }
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<UserOrNull>(null);
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState<JobType[]>([]);
  const [blogs, setBlogs] = useState<BlogType[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const limit = 9;

  useEffect(() => {
    const getBlogs = async () => {
      try {
        const data = await handleGetBlogs(currentPage, limit);
        if (data.status !== 200) {
          toast.error("Blogs Could Not Be Found");
        } else {
          setBlogs(data.data as BlogType[]);
          setTotalPages(data.meta.totalPages as number);
        }
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    getBlogs();
  }, [currentPage]);

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    fetchAllJobs(setLoading, setJobs);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        handlePageChange,
        blogs,
        currentPage,
        totalPages,
        setBlogs,
        isAuthenticated,
        jobs,
        setJobs,
        loading,
        user,
        setUser,
        setIsAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
